
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import * as api from "@/api/index.js";

import "highlight.js/styles/github.css";

import axios from "axios";

@Component({
  components: {},
})
export default class AppContent extends Vue {
  @Prop({ required: true }) videoList: any;
  $refs!: {};

  videoURL: string = "";
  width: number = 0;
  isShowValue: boolean = true;

  created() {}
  mounted() {
    window.addEventListener("resize", this.handleResize);
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(event: any) {
    //모바일화면일때만 v-if해주기
    this.width = window.innerWidth;

    if (this.width > 600) {
      this.isShowValue = true;
    } else {
      this.isShowValue = false;
    }
  }
}
