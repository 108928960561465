
import { Viewer, Editor } from "@toast-ui/vue-editor";
import { Vue, Component, Watch } from "vue-property-decorator";
import * as api from "@/api/index.js";
import cfg from "./farmsInfo.js";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import "highlight.js/styles/github.css";
import codeSyntaxHightlight from "@toast-ui/editor-plugin-code-syntax-highlight";

@Component({
  components: {
    Editor,
    Viewer,
    codeSyntaxHightlight,
  },
})
export default class AppContent extends Vue {
  $refs!: {
    viewer: HTMLFormElement;
    requestInput: HTMLInputElement;
    phoneNumber2: HTMLInputElement;
  };

  page: number = 1;
  userInfo: any = {
    customerName: "",
    item: "",
    area: "",
    phoneNumber: "",
  };
  radioValues: string = "Y";
  columns: number = 3;
  items: any = [
    {
      src: require("./photo/farm_3.png"),
    },
    {
      src: require("./photo/farm_1.png"),
    },
    {
      src: require("./photo/farm_2.png"),
    },
  ];
  width: number = 0;
  isShowValue: boolean = true;
  fade: any = [false, false, false];
  fadeId: number = 0;
  animationInterval: number = 1000; // 애니메이션 간격(ms)
  fullText: string = "";
  displayedText: string = ""; // 현재 화면에 표시되는 텍스트
  typingSpeed: number = 100; // 글자가 나타나는 속도(ms)

  get farmsInfo() {
    return cfg.farmsInfo;
  }

  created() {}
  mounted() {
    window.addEventListener("resize", this.handleResize);
    //setTimeout(() => this.fadeNext, 1000);
    this.fadeNext();
    this.typeText();
    this.fullText =
      "지속가능하며 혁신적인 기술과 품질좋은 묘 생산을 위해 항상 노력하고 있는 스마트팜 전문기업 입니다.";
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
  typeText() {
    let index = 0;
    const interval = setInterval(() => {
      if (index < this.fullText.length) {
        // 한 글자씩 추가
        this.displayedText += this.fullText[index];
        index++;
      } else {
        clearInterval(interval); // 모두 표시되면 중단
      }
    }, this.typingSpeed);
  }

  fadeNext() {
    if (this.fadeId < this.fade.length) {
      // 현재 인덱스의 값을 true로 설정
      this.$set(this.fade, this.fadeId, true);

      // 다음 인덱스를 위해 fadeId를 증가시키고 1초 후 실행
      this.fadeId++;
      setTimeout(this.fadeNext, 1000);
    } else {
      // 모든 애니메이션이 끝난 경우
      this.resetFade();
    }
  }
  resetFade() {
    // 상태 초기화
    this.fade = this.fade.map(() => false); // 모든 항목을 false로 설정
    this.fadeId = 0; // 인덱스 초기화
    setTimeout(this.fadeNext, this.animationInterval); // 다시 시작
  }
  handleResize(event: any) {
    //모바일화면일때만 v-if해주기
    this.width = window.innerWidth;

    if (this.width > 600) {
      this.isShowValue = true;
    } else {
      this.isShowValue = false;
    }
  }

  MoveToSample() {
    var element = document.querySelector("#sample");
    if (element !== null) {
      var location = (element as HTMLElement).offsetTop;
      window.scrollTo({ top: location, behavior: "smooth" });
    }
  }
  checkPhoneNumber1() {
    if (this.userInfo.phoneNumber_2.length === 4) {
      const phoneNumber2Input = this.$refs.phoneNumber2 as HTMLInputElement;
      phoneNumber2Input.focus();
    }
  }
  checkPhoneNumber2() {
    if (this.userInfo.phoneNumber_3.length === 4) {
      const requestInput = this.$refs.requestInput as HTMLInputElement;
      requestInput.focus();
    }
  }
  MoveToInfo() {
    var element = document.querySelector("#info");
    if (element !== null) {
      var location = (element as HTMLElement).offsetTop;
      window.scrollTo({ top: location, behavior: "smooth" });
    }
  }
  preSubmit() {
    let body = {
      area: this.userInfo.area,
      customerName: this.userInfo.customerName,
      phoneNumber: this.userInfo.phoneNumber,
      request: this.userInfo.item,
    };
    if (body.customerName == "") {
      alert("이름을 입력해주세요.");
    } else if (body.area == "") {
      alert("재배면적을 입력해주세요.");
    } else if (body.phoneNumber == "") {
      alert("연락처를 입력해주세요.");
    } else if (body.request == "") {
      alert("요청사항을 입력해주세요.");
    } else {
      api.landing
        .postCustomerInfo(body)
        .then((res: any) => {
          alert("참여가 완료되었습니다.");
          this.userInfo = "";
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          console.log("항상 마지막에 실행");
        });
    }
  }
}
