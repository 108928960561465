
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Map extends Vue {
  map: any = null;

  mounted() {}

  initMap() {
    const container = document.getElementById("map");

    // 맵 컨테이너가 없는 경우 처리
    if (!container) {
      console.error("Map container not found!");
      return;
    }

    // Kakao Maps SDK가 로드되었는지 확인
    if (!window.kakao || !window.kakao.maps) {
      console.error("Kakao Maps SDK is not loaded properly.");
      return;
    }

    console.log("Kakao Maps SDK loaded:", window.kakao.maps);

    // Kakao Maps API의 'load' 메서드 사용
    window.kakao.maps.load(() => {
      // API가 완전히 준비된 상태인지 다시 확인
      if (!window.kakao.maps.LatLng) {
        console.error("Kakao Maps LatLng constructor not found.");
        return;
      }

      console.log("LatLng constructor exists:", window.kakao.maps.LatLng);

      // 맵 옵션 설정
      const options = {
        center: new window.kakao.maps.LatLng(
          37.12193228891862,
          127.06772864335763
        ), // 기본 위치
        level: 3, // 확대 레벨
      };

      // 맵 초기화
      this.map = new window.kakao.maps.Map(container, options);
      console.log("Map initialized successfully:", this.map);

      var marker = new window.kakao.maps.Marker({
        // 지도 중심좌표에 마커를 생성합니다
        position: this.map.getCenter(),
      });
      // 지도에 마커를 표시합니다
      marker.setMap(this.map);

      // 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
      var zoomControl = new window.kakao.maps.ZoomControl();
      this.map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);

      // 지도가 확대 또는 축소되면 마지막 파라미터로 넘어온 함수를 호출하도록 이벤트를 등록합니다
      window.kakao.maps.event.addListener(
        this.map,
        "zoom_changed",
        function () {}
      );
    });
  }
}
