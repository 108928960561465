
import { Viewer, Editor } from "@toast-ui/vue-editor";
import { Vue, Component, Watch } from "vue-property-decorator";
import * as api from "@/api/index.js";
import cfg from "./farmsInfo.js";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import "highlight.js/styles/github.css";
import codeSyntaxHightlight from "@toast-ui/editor-plugin-code-syntax-highlight";

@Component({
  components: {
    Editor,
    Viewer,
    codeSyntaxHightlight,
  },
})
export default class AppContent extends Vue {
  $refs!: {
    viewer: HTMLFormElement;
    requestInput: HTMLInputElement;
  };

  gridCfg: any = {
    totalCount: 0,
    page: 1,
    perPage: 9,
  };
  pageBoard: any = [];
  detailBoard: any = [];
  board: any = [];
  content: string = "";
  newDialog: boolean = false;
  page: number = 1;

  radioValues: string = "Y";
  columns: number = 3;

  width: number = 0;
  isShowValue: boolean = true;

  @Watch("gridCfg", { deep: true })
  check() {
    let tempArr = [];
    tempArr = [...this.board];

    this.pageBoard = tempArr.slice(
      (this.gridCfg.page - 1) * this.gridCfg.perPage,
      this.gridCfg.page * this.gridCfg.perPage
    );

    return this.pageBoard;
  }

  get farmsInfo() {
    return cfg.farmsInfo;
  }

  created() {
    this.getBoard();
  }
  mounted() {
    window.addEventListener("resize", this.handleResize);
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(event: any) {
    //모바일화면일때만 v-if해주기
    this.width = window.innerWidth;

    if (this.width > 600) {
      this.isShowValue = true;
    } else {
      this.isShowValue = false;
    }
  }

  getBoard() {
    api.landing.getBoardList().then((response: any) => {
      this.board = response.data.responseData;
      this.gridCfg.totalCount = this.board.length;

      this.board.forEach((value: any) => {
        if (value.content.indexOf("<img src=") != -1) {
          var s = value.content.indexOf("<img src=") + 10;
          var e = value.content.indexOf("alt") - 2;
          value["url"] = value.content.substring(s, e);
        } else {
          value["url"] = "";
        }
      });
    });
  }
  detailNews(item: number) {
    api.landing
      .getImageBoardDetailInfo(item)
      .then((response: any) => {
        this.detailBoard = response.data.responseData;
        this.content = this.detailBoard.content;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (this.$refs.viewer != undefined) {
          this.$refs.viewer.invoke("setMarkdown", this.content, true);
        }
        this.newDialog = true;
      });
  }
  close() {
    this.newDialog = false;
    this.content = "";
  }
}
