
import { Vue, Component } from "vue-property-decorator";

@Component
export default class BrandInfo extends Vue {
  skillList: any = [
    {
      title: "재배",
      url: require("./photo/skill1.png"),
      info: "전문적인 재배사분의 관리",
    },
    {
      title: "설비",
      url: require("./photo/skill2.png"),
      info: "최적의 환경을 위한 설비 보유",
    },
    {
      title: "모니터링 프로그램",
      url: require("./photo/skill3.png"),
      info: "데이터분석 프로그램 보유",
    },
  ];
  snsList: any = [
    {
      title: "스 토 어 팜",
      url: require("./photo/store.png"),
      link: "https://smartstore.naver.com/ksseedling",
    },
    {
      title: "블 로 그",
      url: require("./photo/blog.png"),
      link: "https://blog.naver.com/ksseedling",
    },
    {
      title: "유 튜 브",
      url: require("./photo/Youtube.png"),
      link: "https://www.youtube.com/@ksseedling",
    },
    {
      title: "인 스 타 그 램",
      url: require("./photo/Instagram.png"),
      link: "https://www.instagram.com/raising_seed",
    },
  ];
  mounted() {}
  clickSns(link: any) {
    window.open(link, "_blank");
  }
}
