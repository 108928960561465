<template>
  <v-app class="ma-0 pa-0">
    <v-main>
      <AppHeader
        @move-to-sample="MoveToSample"
        @move-to-info="MoveToInfo"
        @current-menu="changeMenu"
        class="header"
      ></AppHeader>

      <AppContent
        ref="methodRequest"
        v-show="currentPage == 'appContent'"
      ></AppContent>
      <SNS v-show="currentPage == 'SNS'"> </SNS>
      <News v-show="currentPage == 'news'"> </News>
      <Video v-show="currentPage == 'video'" :videoList="videoList"></Video>
      <!--<ReservationCheck
        v-show="currentPage == 'check'"
        :pageStatus="status"
      ></ReservationCheck>
      <ReservationRequest
        v-show="currentPage == 'request'"
        @current-check="changecheck"
      ></ReservationRequest>-->
      <Map v-show="currentPage == 'map'" ref="map"></Map>
      <AppFooter></AppFooter>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";
import AppContent from "./views/content/AppContent.vue";
import ReservationCheck from "./views/content/ReservationCheck.vue";
import ReservationRequest from "./views/content/ReservationRequest.vue";
import Map from "./views/content/Map.vue";
import News from "./views/content/News.vue";
import Video from "./views/content/Video.vue";
import SNS from "./views/content/BrandInfo.vue";
import axios from "axios";
import _ from "lodash";
export default {
  name: "App",
  metaInfo() {
    return {
      title: "한국표준육묘",
      // titleTemplate: "test | %s",
      meta: [
        {
          name: "title",
          vmid: "title",
          content: "한국표준육묘",
        },
        {
          name: "description",
          vmid: "description",
          content:
            "한국표준육묘는 국내 최초의 수직형 LED 육묘업체으로 최적의 육묘 솔루션을 제공하여 우수한 품질의 묘를 생산합니다",
        },
        {
          property: "og:type",
          content: "website",
          vmid: "og:type",
        },
        {
          property: "og:title",
          content: "한국표준육묘",
          vmid: "og:title",
        },
        {
          property: "og:description",
          content:
            "한국표준육묘는 국내 최초의 수직형 LED 육묘업체으로 최적의 육묘 솔루션을 제공하여 우수한 품질의 묘를 생산합니다",
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: "../src/views/content/photo/logo_seedling.png",
          vmid: "og:image",
        },
        {
          property: "og:image:alt",
          content: "한국표준육묘 LOGO",
          vmid: "og:image:alt",
        },
        {
          property: "og:url",
          content: "https://ksseedling.com/",
          vmid: "og:url",
        },
        {
          name: "naver-site-verification",
          vmid: "naver-site-verification",
          content: "1319c48ad273f4f3c3452800928e48406af69d5d",
        },
        {
          name: "naver-site-verification",
          vmid: "naver-site-verification",
          content: "83feca07859e5bca82724c34dcb8cf9f81467cfc",
        },
      ],
    };
  },
  data() {
    return {
      isSample: false,
      isInfo: false,
      currentPage: "",
      status: "",
      videoList: [],
    };
  },

  components: {
    AppHeader,
    AppContent,
    AppFooter,
    ReservationCheck,
    ReservationRequest,
    News,
    Video,
    Map,
    SNS,
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  watch: {
    currentPage: function (val) {
      console.log("===", this.currentPage);
      if (this.currentPage == "map") {
        this.loadScript();
      } else if (this.currentPage == "video") {
        this.getVideo();
      }
    },
  },

  created() {},

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.currentPage = "appContent";
  },

  methods: {
    loadScript() {
      const script = document.createElement("script");
      script.src =
        "https://dapi.kakao.com/v2/maps/sdk.js?appkey=7404bf6f6061f3b6df24e909523ee26c&libraries=services&autoload=false";

      // 스크립트가 성공적으로 로드된 후에만 initMap 실행
      script.onload = () => {
        if (window.kakao && window.kakao.maps) {
          console.log("Kakao Map API loaded successfully.");
          this.$refs.map.initMap();
        } else {
          console.error("Failed to load Kakao Map API properly.");
        }
      };

      script.onerror = () => {
        console.error("Failed to load Kakao Map API.");
      };

      document.head.appendChild(script);
    },
    getVideo() {
      axios({
        method: "get",
        url: "https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=25&channelId=UC7_Oqs93doyYAFjXkrEPVJg&type=video&key=AIzaSyBnNTvlRj8GOX9KWvyzlPABC47gzCA2Xkw",
      })
        .then((response) => {
          console.log((this.videoList = _.map(response.data.items, "id")));
          this.videoList = _.map(response.data.items, "id"); // 여기서 'this'가 Vue 컴포넌트를 가리킴
          console.log(this.videoList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onResize() {
      /* this.isMobile = window.innerWidth < 600 */
    },
    MoveToSample(data) {
      this.$refs.methodRequest.MoveToSample(data);
    },
    MoveToInfo(data) {
      this.$refs.methodRequest.MoveToInfo(data);
    },
    changeMenu(data) {
      this.currentPage = data;

      return this.currentPage;
    },
  },
};
</script>
<style>
@font-face {
  font-family: "Puradak_header";
  src: url("/src/assets/fonts/Puradak\ Gentle\ Gothic.ttf") format("woff");
  font-style: normal;
  font-weight: normal;
}

.header {
  font-family: "Puradak_header";
}
</style>
