import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/AppContent",
    name: "AppContent",
    component: () => import("@/views/content/AppContent"),
  },
  {
    path: "/AppHeader",
    name: "AppHeader",
    component: () => import("@/components/AppHeader"),
  },
  {
    path: "/AppFooter",
    name: "AppFooter",
    component: () => import("@/components/AppFooter"),
  },
  {
    path: "/m_AppContent",
    name: "m_AppContent",
    component: () => import("@/views/content/m_AppContent.vue"),
  },
  {
    path: "/ReservationRequest",
    name: "ReservationRequest",
    component: () => import("@/views/content/ReservationRequest.vue"),
  },
  {
    path: "/ReservationCheck",
    name: "ReservationCheck",
    component: () => import("@/views/content/ReservationCheck.vue"),
  },
  {
    path: "/Video",
    name: "Video",
    component: () => import("@/views/content/Video.vue"),
  },
  {
    path: "/News",
    name: "News",
    component: () => import("@/views/content/News.vue"),
  },
  {
    path: "/Map",
    name: "Map",
    component: () => import("@/views/content/Map.vue"),
  },
  {
    path: "/SNS",
    name: "SNS",
    component: () => import("@/views/content/BrandInfo.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
