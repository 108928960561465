
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class AppHeader extends Vue {
  isSample: boolean = false;
  isInfo: boolean = false;

  MoveToSample() {
    this.$emit("current-menu", "appContent");
    this.$emit("move-to-sample", this.isSample);
  }
  MoveToInfo() {
    this.$emit("current-menu", "appContent");
    this.$emit("move-to-info", this.isInfo);
  }
  MoveToNews() {
    this.$emit("current-menu", "news");
  }
  MoveToVideo() {
    this.$emit("current-menu", "video");
  }

  MoveToMap() {
    //this.$router.push("/Map").catch(() => {});
    this.$emit("current-menu", "map");
  }
  MoveToSns() {
    //this.$router.push("/Map").catch(() => {});
    this.$emit("current-menu", "SNS");
  }
}
